<template>
  <PageCover :active-path="'covid'"
             image="/img/cover-rezervari.webp"
             page-title="Regulament Covid">
  </PageCover>
  <div class="mt-5">
    <section class="container text-start">
      <div>
        <p>
          Regulament de acces și desfășurare activități în locația
          <strong>BUNKER SOCIAL GAMING ARENA</strong>
        </p>
        <p class="pt-5">
          Accesul la Bunker se face exclusiv pe bază de <strong>programare</strong>
          cu cel puțîn 24 ore înainte, prin telefon sau site.
          <br><br>
          Pentru petreceri și aniversări, este nevoie de <strong>minim 8</strong>,
          <strong>maxim 20</strong> de participanți.
          În această situație, locația este exclusivă evenimentului respectiv.
          <br><br>
          Evenimentul va avea loc numai după confirmarea noastră.
          Pentru acces în sală este nevoie şi de completarea obligatorie a acordului de
          participare,
          iar pentru minori acordul trebuie completat de un părinte/tutore/adult la față locului.
          <br><br>
          La intrare, toți clienții sunt obligați să își dezinfecteze mâinile cu soluțiile puse la
          dispoziție.
          <br><br>
          Va rugăm să respectați ora stabilită la rezervare, astfel încât să ajungeți cu
          maxim 10 minute înainte de ora începerii evenimentului, și nu mai devreme.
          <br><br>
          Este interzis să aduceți mâncare de acasă, orice produs de catering/consum trebuie
          însoțit de certificat calitate/conformitate.
          <br><br>
          Elementele folosite de mai multe persoane (mânerele uşilor, controlere,
          întrerupătoare etc) sunt igienizate periodic cu substanţe biocide avizate în acest scop.
          <br><br>
          Încăperile vor fi igienizate, dezinfectate și aerisite între evenimente.
          Bunker folosește lămpi UVC pentru sterilizarea periodică a încăperilor și a
          accesoriilor. Din acest motiv, trebuie respectate intervalele orare aferente
          fiecărui event.
          <br><br>
          Stuff-ul nostru folosește măști de protecție și are schemă de vaccinare anti Covid-19
          completă+booster, deasemenea, o dată pe săptămâna ne testăm cu test antigen rapid.
        </p>
      </div>
    </section>

  </div>
  <Footer></Footer>
</template>

<script>
import PageCover from '../components/PageCover.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Covid',
  components: {
    PageCover,
    Footer,
  },
};
</script>

<style scoped lang="scss">
 p {
   font-size: 20px;
 }
</style>
